const expandStyles = {
    content: {
        width: '77%',
        // maxWidth: '1100px',
        height: '100%',
        // maxHeight: '600px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    overlay: {
        zIndex: 999999999,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
};

export default expandStyles;
