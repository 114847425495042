import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { PRODUCT_CATEGORY } from "../../utils/constants/product-category.constants";

export const productCategoryApi = createApi({
  reducerPath: "productCategoryApi",
  tagTypes: ["ProductCategory"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getAllProductCategories: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search?: string;
        product_name?: string;
      }
    >({
      query: ({ page = 1, limit = 10, search, product_name }) => {
        const queryParams = {
          url: PRODUCT_CATEGORY.GET_ALL,
          params: { page, limit },
        };

        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (product_name) {
          //@ts-ignore
          queryParams.params.product_name = product_name;
        }

        return queryParams;
      },
    }),
    createProductCategory: builder.mutation({
      query: (body) => ({
        url: PRODUCT_CATEGORY.CREATE,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProductCategory"],
    }),
    updateProductCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `${PRODUCT_CATEGORY.UPDATE}/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["ProductCategory"],
    }),
    deleteProductCategory: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT_CATEGORY.DELETE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductCategory"],
    }),
  }),
  keepUnusedDataFor:0
});

export const {
  useGetAllProductCategoriesQuery,
  useLazyGetAllProductCategoriesQuery,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation,
} = productCategoryApi;
