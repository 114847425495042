import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PRIMARY_SALE_ORDER } from '@utils/constants/primary-sale-order.constants';
import { TRIP_SALES_ORDER } from '@utils/constants/trip/trip-sales-order.constants';
import { CONSIGNMENT_CONTRACT } from '@utils/constants/consignment/consignment-contract.constants';

export const consignmentContractApi = createApi({
    reducerPath: 'consignmentContractApi',
    tagTypes: ['ConsignmentContract'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllConsignmentContracts: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                business_unit_id?:string | number;
                customer_id?:string | number;
            }
        >({
            query: ({ page = 1, limit = 10 ,business_unit_id,customer_id}) => {
                const queryParams = {
                    url: CONSIGNMENT_CONTRACT.GET_ALL,
                    params: { page, limit },
                };

                if (business_unit_id) {
                    //@ts-ignore
                    queryParams.params.business_unit_id = business_unit_id;
                }

                if (customer_id) {
                    //@ts-ignore
                    queryParams.params.customer_id = customer_id;
                }


                return queryParams;
            },
        }),
        getConsignmentContract: builder.query<any, { id: string | number }>({
            query: ({ id }) => `${CONSIGNMENT_CONTRACT.GET_ONE}${id}`,
        }),
        getDetailContract: builder.query<any, { id: string | number }>({
            query: ({ id }) => CONSIGNMENT_CONTRACT.DETAIL_ONE + id,
        }),
        createConsignmentContract: builder.mutation({
            query: (body) => ({
                url: CONSIGNMENT_CONTRACT.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateConsignmentContract: builder.mutation({
            query: ({ id, body }) => ({
                url: `${CONSIGNMENT_CONTRACT.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteConsignmentContract: builder.mutation({
            query: (id) => ({
                url: `${CONSIGNMENT_CONTRACT.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllConsignmentContractsQuery,
    useGetConsignmentContractQuery,
    useCreateConsignmentContractMutation,
    useUpdateConsignmentContractMutation,
    useLazyGetDetailContractQuery,
    useDeleteConsignmentContractMutation,
} = consignmentContractApi;
