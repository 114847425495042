import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';
import CustomButton from '../../buttons/CustomButton';
import expandStyles from '../styles/expandStyles';
import ItemListTable from '@components/extra/sales-order/ItemListTable';
import TripProgressTable from '@components/extra/trip/TripProgress';
import { formatDBDate } from '@utils/general/format-db-date';
import { formatDate } from '@utils/general/format-date';
import { thousandSeperator } from '@utils/general/digit-separators';
import CustomInputField from '@components/form/CustomInputField';

interface InvoiceDetailModalProps {
    data: any;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoiceDetailModal: React.FC<InvoiceDetailModalProps> = ({
    data,
    isOpen,
    setIsOpen,
}) => {
    const [target, setTarget] = useState(1);

    const grandTotalSum = data?.products?.reduce((acc: any, cur: any) => {
        return acc + cur.amount;
    }, 0);

    const okayData = useMemo(
        () => [
            ...(data?.products?.map((d: any, i: number) => ({
                ...d,
                no: i,
            })) || []),
            {
                product_code: 'Totals:',
                amount: grandTotalSum,
                noAction: true,
            },
        ],
        [data]
    );

    console.log(data, 'data');

    const changeTarget = () => {
        setTarget((prevTarget) => (prevTarget === 1 ? 2 : 1));
    };

    return (
        <Modal
            isOpen={isOpen}
            style={expandStyles}
            contentLabel="Example Modal"
        >
            <div className="relative h-full">
                <div className="flex justify-end">
                    <svg
                        onClick={() => setIsOpen(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        viewBox="0 -960 960 960"
                        width="20px"
                        fill="#3e3e3e"
                        className="cursor-pointer"
                    >
                        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                    </svg>
                </div>
                <div className="py-4 px-14">
                    <div className="grid grid-cols-3 gap-x-8 gap-y-7">
                        <div className="flex justify-between">
                            <div className="font-[500] basis-5/12">
                                Consignment Id
                            </div>
                            <div className="basis-1/12">:</div>
                            <div className="basis-6/12 font-[300] text-[0.85rem]">
                                {data.consignment_invoice_code}
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="font-[500] basis-5/12">
                                Started Date
                            </div>
                            <div className="basis-1/12">:</div>
                            <div className="basis-6/12 font-[300] text-[0.85rem]">
                                {formatDate(new Date(data.invoice_date))}
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="font-[500] basis-6/12">
                                Invoice Date
                            </div>
                            <div className="basis-1/12">:</div>
                            <div className="basis-5/12 font-[300] text-[0.85rem]">
                                {formatDate(new Date(data.invoice_date))}
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <div className="font-[500] basis-5/12">Status</div>
                            <div className="basis-1/12">:</div>
                            <div className="basis-6/12 font-[300] text-[0.85rem]">
                                {data.status == 1
                                    ? 'Open'
                                    : data.status == 2
                                    ? 'Invoice Created'
                                    : data.status == 3
                                    ? 'Approved & Open'
                                    : data.status == 4
                                    ? 'Rejected'
                                    : 'Closed'}
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="font-[500] basis-5/12">
                                Customer Name
                            </div>
                            <div className="basis-1/12">:</div>
                            <div className="basis-6/12 font-[300] text-[0.85rem]">
                                {data.customer_first_name +
                                    ' ' +
                                    (data.customer_last_name || '')}
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="font-[500] basis-6/12">
                                Business Unit Name
                            </div>
                            <div className="basis-1/12">:</div>
                            <div className="basis-5/12 font-[300] text-[0.85rem]">
                                {data.business_unit_name || '-'}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between p-4 mt-6 mb-5 bg-gray-100">
                        <div className="flex">
                            <div className="font-semibold text-primary-dark">
                                Invoice
                            </div>
                            <div className="mx-10 text-center"></div>
                            <div className="font-semibold text-primary-dark">
                                {data.consignment_code}
                            </div>
                        </div>
                    </div>

                    <ItemListTable data={okayData} />

                    {data?.products?.length > 0 && (
                        <div className="bg-zinc-100 opacity-[0.9] rounded   mt-8  p-6">
                            <div className="flex items-center mb-4">
                                <h5 className="text-[1.05rem] basis-3/12">
                                    Sub Total
                                </h5>
                                <span className="s basis-1/12">:</span>
                                <div className="flex items-center basis-6/12"></div>
                                <h5 className="text-right basis-2/12 text-[1.05rem]">
                                    {thousandSeperator(data.sub_total || 0)}
                                </h5>
                            </div>
                            <div className="flex items-center mb-2">
                                <h5 className=" basis-3/12 text-[1.05rem]">
                                    Discount
                                </h5>
                                <span className=" basis-1/12">:</span>
                                <div className="flex items-center basis-6/12">
                                    <div
                                        className={`px-8 py-3 text-primary-dark border-[0.7px] min-w-[100px] w-[100%] focus:ring-1 bg-zinc-100 cursor-not-allowed ring-primary focus:outline-none rounded border-primary-light`}
                                    >
                                        {data.discount}
                                    </div>
                                    <select
                                        value={data.discount_type}
                                        disabled
                                        className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                    >
                                        <option value="AMT">AMT</option>
                                        <option value="%">%</option>
                                    </select>
                                </div>
                                <h5 className="text-right basis-2/12 text-[1.05rem]">
                                    -
                                    {thousandSeperator(
                                        data.discount_amount || 0
                                    )}
                                </h5>
                            </div>
                            <div className="flex items-center mb-2">
                                <h5 className=" basis-3/12 text-[1.05rem]">
                                    Tax
                                </h5>
                                <span className=" basis-1/12">:</span>
                                <div className="flex items-center basis-6/12">
                                    <div
                                        className={`px-8 py-3 text-primary-dark border-[0.7px] min-w-[100px] w-[100%] focus:ring-1 bg-zinc-100 cursor-not-allowed ring-primary focus:outline-none rounded border-primary-light`}
                                    >
                                        {data.tax}
                                    </div>
                                    <select
                                        value={data.tax_type}
                                        disabled
                                        className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                    >
                                        <option value="AMT">AMT</option>
                                        <option value="%">%</option>
                                    </select>
                                </div>
                                <h5 className="text-right basis-2/12 text-[1.05rem]">
                                    {thousandSeperator(data.tax_amount || 0)}
                                </h5>
                            </div>
                            <div className="flex items-center mb-4">
                                <h5 className=" basis-3/12 text-[1.05rem]">
                                    Other Charges
                                </h5>
                                <span className=" basis-1/12">:</span>
                                <div className="flex items-center basis-6/12">
                                    <div
                                        className={`px-8 py-3 text-primary-dark border-[0.7px] min-w-[100px] w-[100%] focus:ring-1 bg-zinc-100 cursor-not-allowed ring-primary focus:outline-none rounded border-primary-light`}
                                    >
                                        {data.other_charges}
                                    </div>
                                </div>
                                <h5 className="text-right basis-2/12 text-[1.05rem]">
                                    {thousandSeperator(data.other_charges || 0)}
                                </h5>
                            </div>
                            <div className="flex items-center mb-2">
                                <h5 className="text-xl font-semibold basis-3/12">
                                    Grand Total
                                </h5>
                                <span className=" basis-1/12">:</span>
                                <div className="flex items-center basis-6/12"></div>
                                <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                                    {thousandSeperator(
                                        data.grand_total_amount || 0
                                    )}
                                </h5>
                            </div>
                        </div>
                    )}

                    <div className="float-right pb-8 mt-10">
                        <CustomButton
                            handleClick={() => setIsOpen(false)}
                            color="bg-primary-dark"
                            className="px-8 py-3"
                            title="Close"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default InvoiceDetailModal;
