import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { EXPNESE } from '@utils/constants/expense.constants';
import { LOCAL_STORAGE_KEYS } from '@utils/constants/local-storeage-keys.constants';

export const expneseApi = createApi({
    reducerPath: 'expneseApi',
    tagTypes: ['Expense'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
         // credentials: 'include',
        prepareHeaders: (headers, { getState }) => {
            const currentState: any = getState();

            const token =
                currentState.auth.accessToken ||
                localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_KEY);

            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllExpenses: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                username?: string;
                status?: string;
                from_date?: Date | string;
                to_date?: Date | string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                username,
                status,
                from_date,
                to_date,
            }) => {
                const queryParams = {
                    url: EXPNESE.GET_ALL,
                    params: { page, limit },
                };

                if (username) {
                    //@ts-ignore
                    queryParams.params.username = username;
                }

                if (status) {
                    //@ts-ignore
                    queryParams.params.status = status;
                }

                if (from_date) {
                    //@ts-ignore
                    queryParams.params.from_date = from_date;
                }

                if (to_date) {
                    //@ts-ignore
                    queryParams.params.to_date = to_date;
                }

                return queryParams;
            },
        }),
        getExpense: builder.query<[], { id: string | number }>({
            query: ({ id }) => {
                return `${EXPNESE.GET_ONE}${id}`;
            },
        }),
        createExpense: builder.mutation({
            query: (body) => ({
                url: EXPNESE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateExpense: builder.mutation({
            query: ({ id, body }) => ({
                url: `${EXPNESE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteExpense: builder.mutation({
            query: (id) => ({
                url: `${EXPNESE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllExpensesQuery,
    useGetExpenseQuery,
    useCreateExpenseMutation,
    useUpdateExpenseMutation,
    useDeleteExpenseMutation,
} = expneseApi;
